import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

function SpaceDust() {
    const mountRef = useRef(null);

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        //const renderer = new THREE.WebGLRenderer();
        const renderer = new THREE.WebGLRenderer({ alpha: true }); // Enable transparent background


        // Set the renderer size to fill the entire window
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(window.devicePixelRatio); // Handle high-DPI displays
        renderer.setClearColor(0x000000, 0); // Transparent background

        // Append the renderer's canvas to the container
        if (mountRef.current) {
            mountRef.current.appendChild(renderer.domElement);
        }

        // Create particles
        const geometry = new THREE.BufferGeometry();
        const count = 500; // Reduce the number of particles here
        const positions = new Float32Array(count * 3);

        for (let i = 0; i < count * 3; i++) {
            positions[i] = (Math.random() - 0.5) * 1000;
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        const material = new THREE.PointsMaterial({ color: 0xffffff, size: 0.5 });
        const particles = new THREE.Points(geometry, material);
        scene.add(particles);

        camera.position.z = 20;

        const animate = () => {
            requestAnimationFrame(animate);
            particles.rotation.y += 0.001; // Add rotation to create a dynamic effect
            renderer.render(scene, camera);
        };

        animate();

        // Handle window resizing
        const handleResize = () => {
            renderer.setSize(window.innerWidth, window.innerHeight);
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
            if (mountRef.current) {
              mountRef.current.removeChild(renderer.domElement);
            }
          };
    }, []);

    return <div ref={mountRef} style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} />;
}

export default SpaceDust;
