import React, { useEffect, useState } from 'react';
import './CursorLight.css';

function CursorLight({ checkeredBackgroundRef }) {
    const [lightPosition, setLightPosition] = useState({ x: 0, y: 0 });
    const [isShaking, setIsShaking] = useState(false);
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    useEffect(() => {
        if (isTouchDevice) return; // Exit if on a touch device

        const handleMouseMove = (event) => {
            setLightPosition({ x: event.clientX, y: event.clientY });

            if (checkeredBackgroundRef.current) {
                const checkeredRect = checkeredBackgroundRef.current.getBoundingClientRect();
                const isWithinCheckeredBackground =
                    event.clientX >= checkeredRect.left &&
                    event.clientX <= checkeredRect.right &&
                    event.clientY >= checkeredRect.top &&
                    event.clientY <= checkeredRect.bottom;

                if (isWithinCheckeredBackground) {
                    const radius = 80;
                    const streaks = document.querySelectorAll('.streak');

                    let shouldShake = false;

                    streaks.forEach(streak => {
                        const rect = streak.getBoundingClientRect();
                        const dx = Math.max(rect.left - event.clientX, 0, event.clientX - rect.right);
                        const dy = Math.max(rect.top - event.clientY, 0, event.clientY - rect.bottom);
                        const distance = Math.sqrt(dx * dx + dy * dy);

                        if (distance < radius) {
                            shouldShake = true;
                        }
                    });

                    if (shouldShake && !isShaking) {
                        setIsShaking(true);
                        document.body.classList.add('shake');
                        setTimeout(() => {
                            document.body.classList.remove('shake');
                            setIsShaking(false);
                        }, 500); // Duration of the shake effect
                    }
                }
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => document.removeEventListener('mousemove', handleMouseMove);
    }, [isShaking, checkeredBackgroundRef, isTouchDevice]);

    if (isTouchDevice) return null; // Do not render on touch devices

    return (
        <div
            className="cursor-light"
            style={{ top: lightPosition.y, left: lightPosition.x }}
        ></div>
    );
}

export default CursorLight;
