import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css';
import IntroductionSection from './IntroductionSection/IntroductionSection';


import DustParticles from '../DustParticles/DustParticles';
import LanguageToggle from '../LanguageToggle/LanguageToggle';
import SpaceDust from '../SpaceDust/SpaceDust';
import SpaceNavigation from './SpaceNavigation/SpaceNavigation';
import OrbitingStars from '../CursorLight/OrbitingStars/OrbitingStars';
import CursorLight from '../CursorLight/CursorLight';
import CheckeredSection from './CheckeredSection/CheckeredSection';

function HomePage() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const checkeredBackgroundRef = useRef(null); // Create the ref
  const sectionRefs = useRef([]);


  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setScrollPosition(scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <div className="wrapper-background">
      <DustParticles />
      <SpaceDust />
      <OrbitingStars />
      <CursorLight checkeredBackgroundRef={checkeredBackgroundRef} />

      <div className="section" ref={(el) => (sectionRefs.current[0] = el)}>
        <div className="section-content">
          <CheckeredSection ref={checkeredBackgroundRef} />
        </div>
      </div>

      <div className="section" ref={(el) => (sectionRefs.current[1] = el)}>
        <div className="section-content">
          <IntroductionSection scrollPosition={scrollPosition} />
        </div>
      </div>

      <div className="section" ref={(el) => (sectionRefs.current[2] = el)}>
        <div className="section-content">
          <SpaceNavigation />
        </div>
      </div>






    </div>
  );
}

export default HomePage;
