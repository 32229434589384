import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollTo = ({ X = 0, Y = 0, children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(X, Y);
  }, [location, X, Y]); // Dependency array includes location, X, Y

  return <>{children}</>;
};

export default ScrollTo;
