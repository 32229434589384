import React, { useEffect, useRef, useState } from 'react';
import './ProjectsPage.css';
import OrbitingStars from '../CursorLight/OrbitingStars/OrbitingStars';
import SpaceDust from '../SpaceDust/SpaceDust';
import DustParticles from '../DustParticles/DustParticles';
import useTranslations from '../../hooks/useTranslations';

import image1 from '../../assets/images/projects/wrex/tese1a.jpg';
import image2 from '../../assets/images/projects/wrex/tese2a.jpg';
import image3 from '../../assets/images/projects/wrex/tese3a.jpg';
import image4 from '../../assets/images/projects/wrex/tese4a.jpg';
import image5 from '../../assets/images/projects/wrex/tese5a.jpg';

import bus1 from '../../assets/images/projects/bus/bus1.png';


const ProjectsPage = () => {
  const demoTexts = useRef([]); // Para a porcentagem de scroll
  const stickyRefs = useRef([]); // Referências dos itens sticky

  const [activeStickyIdx, setActiveStickyIdx] = useState(-1); // State for image index
  const activeStickyIdxRef = useRef(-1); // Referência para o texto de "descoberta"


  const discoveryTextRef = useRef(null); // Referência para o texto de "descoberta"
  const [currentImageFirstIndex, setCurrentImageIFirstIndex] = useState(0); // State for image index
  const [currentImageSecondIndex, setCurrentImageSecondIndex] = useState(0); // State for image index

  const translations = useTranslations();
  const projects = translations.projectsPage.projects || [];
  const projectImages = [{ img: image1, size: 30 }, { img: image2, size: 30 }, { img: image3, size: 30 }, { img: image4, size: 15 }, { img: image5, size: 15 }];
  const busImages = [{ img: bus1, size: 35 }];


  const prevScrollYRef = useRef(0);
  const directionScrollRef = useRef(0);


  const handleNextImage = (project) => {
    if (project == 1) {
      setCurrentImageIFirstIndex((prevIndex) =>
        prevIndex === projectImages.length - 1 ? 0 : prevIndex + 1
      );
    } else if (project == 2) {
      setCurrentImageSecondIndex((prevIndex) =>
        prevIndex === busImages.length - 1 ? 0 : prevIndex + 1
      );
    }

  };

  const handlePrevImage = (project) => {
    if (project == 1) {
      setCurrentImageIFirstIndex((prevIndex) =>
        prevIndex === 0 ? projectImages.length - 1 : prevIndex - 1
      );
    } else if (project == 2) {
      setCurrentImageSecondIndex((prevIndex) =>
        prevIndex === 0 ? busImages.length - 1 : prevIndex - 1
      );
    }

  };



  useEffect(() => {
    const currentRefs = [...stickyRefs.current];
    const stickyOffsets = currentRefs.map(item => item.offsetTop);

    const handleScroll = () => {


      const currentScrollTop = window.scrollY;
      const scrollDelta = currentScrollTop - prevScrollYRef.current;
      directionScrollRef.current = currentScrollTop > prevScrollYRef.current ? 'down' : 'up';
      prevScrollYRef.current = currentScrollTop;




      currentRefs.forEach((stickyItem, index) => {
        // Verifique se o scrollY está dentro da seção sticky


        const stickyStart = stickyOffsets[index];
        const stickyEnd = stickyStart + window.innerHeight;
        const isInStickyZone = currentScrollTop >= stickyStart && currentScrollTop <= stickyEnd;


        if (isInStickyZone) {


          setActiveStickyIdx(index)


          stickyItem.classList.add('sticky');

          if (directionScrollRef.current === 'down') {
            stickyItem.scrollTop += Math.abs(scrollDelta);
          } else {
            stickyItem.scrollTop -= Math.abs(scrollDelta);
          }

        } else {
          stickyItem.classList.remove('sticky');
          stickyItem.classList.remove(`stickyItem${index + 1}-pos`);



          if (activeStickyIdxRef.current == index) {
            setActiveStickyIdx(-1)
          }


          /*
          if (directionScrollRef.current === 'down') {
            stickyItem.scrollTop = 0;
          }

          if (directionScrollRef.current === 'up') {
            stickyItem.scrollTop = stickyItem.scrollHeight;
          }
            */

          // Se o scrollY for menor que o stickyStart, adicionamos a posição inicial
          if (window.scrollY < stickyStart) {
            stickyItem.classList.add(`stickyItem${index + 1}-pos`);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    activeStickyIdxRef.current = activeStickyIdx;
  }, [activeStickyIdx])



  const getScrollPct = (multiplier) => {
    const scroll = window.scrollY;
    const windowHeight = window.innerHeight;
    const totalHeight = document.body.scrollHeight - windowHeight;
    const pct = Math.round((scroll / totalHeight) * 100 * multiplier);
    return `${pct}%`;
  };

  const divOnRails = (item, stickyValue, itemClass) => {
    if (window.scrollY >= stickyValue && window.scrollY <= stickyValue + window.innerHeight) {
      item.classList.add('sticky');
    } else {
      item.classList.remove('sticky');
      item.classList.remove(itemClass);
      if (window.scrollY <= stickyValue) {
        item.classList.add(itemClass);
      }
    }
  };

  return (
    <div className="projectspage-container">
      <OrbitingStars />
      <SpaceDust />
      <DustParticles />

      {projects.map((project, index) => (
        <React.Fragment key={index}>
          <div className={`section-notsticky`} id={`notStickyItem${1 + index}`}>
            <div>
              {index === 0 && (
                <span className="section-title">{translations.projectsPage.title}</span>
              )}
              <div>
                <span>{project.title}</span>
              </div>
            </div>
          </div>



          <div
            className={`section-sticky stickyItem${index + 1} stickyItem${index + 1}-pos`}
            ref={(el) => (stickyRefs.current[index] = el)}
          >

            <div
              className={`sticky-content`}
            >
              <div className="sticky-content-header">
                <span>{project.title}</span>
                <span>{project.dates}</span>
              </div>
              <div className="sticky-content-body">
                <span>{project.description}</span>
              </div>
              <div className="sticky-content-footer">
                <span>{project.technologies}</span>
              </div>

              {(index === 0 || index === 1) && (
                <div className="image-gallery">
                  {
                    ((index === 0 && projectImages.length > 1) ||
                      (index === 1 && busImages.length > 1)
                    ) &&
                    < div className="arrow-container">
                      <button className="arrow left-arrow" onClick={() => handlePrevImage(index + 1)}>
                        &#8592;
                      </button>
                      <button className="arrow right-arrow" onClick={() => handleNextImage(index + 1)}>
                        &#8594;
                      </button>
                    </div>
                  }
                  {
                    index === 0 ? (
                      <img
                        src={projectImages[currentImageFirstIndex].img}
                        alt={`Project ${currentImageFirstIndex + 1}`}
                        className="project-image"
                        style={{ width: `${projectImages[currentImageFirstIndex].size}vmax` }}
                      />
                    ) : index === 1 ? (
                      <img
                        src={busImages[currentImageSecondIndex].img}
                        alt={`Project ${currentImageSecondIndex + 1}`}
                        className="project-image"
                        style={{ width: `${busImages[currentImageSecondIndex].size}vmax` }}
                      />
                    ) : null
                  }
                </div>
              )}

            </div>
          </div>
        </React.Fragment>
      ))
      }
    </div >
  );
};

export default ProjectsPage;
