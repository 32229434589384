import React, { useEffect } from 'react';
import './ExperiencePage.css';
import '../../App.css';
import OrbitingStars from '../CursorLight/OrbitingStars/OrbitingStars';
import SpaceDust from '../SpaceDust/SpaceDust';
import DustParticles from '../DustParticles/DustParticles';
import useTranslations from '../../hooks/useTranslations';
import pairwiseLogo from '../../assets/images/companies/pairwise.jpg';
import gpLogo from '../../assets/images/companies/grandesplanos.jpg';
import cenimatLogo from '../../assets/images/companies/cenimat.jpg';
import ppLogo from '../../assets/images/companies/padariaportuguesa.jpg';


function ExperiencePage() {

  const translations = useTranslations();
  const experiences = translations?.experiencePage?.experiences;

  const companyImgs = [pairwiseLogo, gpLogo, cenimatLogo, ppLogo]

  useEffect(() => {
    const handleScroll = () => {
      const items = document.querySelectorAll('.timeline-item');
      items.forEach(item => {
        const rect = item.getBoundingClientRect();
        if (rect.top + 100 < window.innerHeight && rect.bottom > 0) {
          item.classList.add('in-view');
        } else {
          //item.classList.remove('in-view');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="experiencepage-container app-noselect">
      <OrbitingStars />
      <SpaceDust />
      <DustParticles />

      <div className="timeline-container">
        <div className="timeline-line"></div>

        <div className="timeline-section-title"><span>{translations?.experiencePage?.title}</span></div>
        {experiences.map((item, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-date">{item.period}</div>
              <div className="timeline-details">
                <div className="experience-header">
                  <img src={companyImgs[index]} alt={`${item.company} logo`} className="experience-image" />
                  <div>
                    <div className="experience-title"><span>{item.title}</span></div>
                    <div className="experience-company"><span>{item.company} | {item.location}</span></div>
                  </div>
                </div>
                <ul className="experience-responsibilities">
                  {item.responsibilities.map((responsibility, i) => (
                    <li key={i}>{responsibility}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExperiencePage;
