import { appConstants } from "../constants";
//import nis from "../../apis/nis";
//import { request, success, failure } from "../helpers/handlingFunctions";
//import axios from "axios";

/*
export const getSessionInfo = (clientId, companyId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    nis
      .get(`/users/info/${clientId}/${companyId}`)
      .then((response) => {
        dispatch(success(appConstants.GET_USER_INFO_SUCCESS, response.data.data));
        resolve(response.data.data);
      })
      .catch((error) => reject(dispatch(failure(appConstants.GET_USER_INFO_FAILURE, error))));
  });
};
*/

export const setLanguage = (language) => ({
  type: appConstants.SET_LANGUAGE_REQUEST,
  payload: language,
});