import * as React from "react";

function SvgPageloader(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 146.74 150.33"
      {...props}
    >
      <path d="M73.37 35.39c1.9 0 3.45-1.54 3.45-3.45V3.45a3.45 3.45 0 00-6.9 0v28.49c0 1.9 1.54 3.45 3.45 3.45z" />
      <path
        d="M56.11 39.33c1.71-.83 2.44-2.89 1.61-4.6L45.35 9.05c-.83-1.71-2.89-2.44-4.6-1.61-1.71.83-2.44 2.89-1.61 4.6L51.5 37.71a3.462 3.462 0 004.61 1.62z"
        opacity={0.95}
      />
      <path
        d="M42.27 50.36c1.19-1.49.94-3.66-.55-4.84L19.44 27.75a3.438 3.438 0 00-4.84.55 3.438 3.438 0 00.55 4.84l22.28 17.77c1.48 1.19 3.65.94 4.84-.55z"
        opacity={0.9}
      />
      <path
        d="M34.59 66.31c.42-1.86-.74-3.7-2.59-4.13L4.21 55.85c-1.86-.42-3.7.74-4.13 2.59-.42 1.86.74 3.7 2.59 4.13l27.78 6.34a3.46 3.46 0 004.14-2.6z"
        opacity={0.85}
      />
      <path
        d="M34.59 84.02a3.436 3.436 0 00-4.13-2.59L2.68 87.76a3.436 3.436 0 00-2.59 4.13 3.436 3.436 0 004.13 2.59L32 88.14a3.445 3.445 0 002.59-4.12z"
        opacity={0.8}
      />
      <path
        d="M42.27 99.97a3.448 3.448 0 00-4.84-.55l-22.28 17.77a3.448 3.448 0 00-.55 4.84 3.448 3.448 0 004.84.55l22.28-17.77a3.449 3.449 0 00.55-4.84z"
        opacity={0.75}
      />
      <path
        d="M56.11 111a3.44 3.44 0 00-4.6 1.61l-12.36 25.67a3.44 3.44 0 001.61 4.6c1.71.83 3.77.11 4.6-1.61l12.36-25.67c.82-1.71.1-3.77-1.61-4.6z"
        opacity={0.7}
      />
      <path
        d="M73.37 114.94a3.45 3.45 0 00-3.45 3.45v28.49a3.45 3.45 0 006.9 0v-28.49c-.01-1.9-1.55-3.45-3.45-3.45z"
        opacity={0.65}
      />
      <path
        d="M90.63 111c-1.71.83-2.44 2.89-1.61 4.6l12.36 25.67c.83 1.71 2.89 2.44 4.6 1.61 1.71-.83 2.44-2.89 1.61-4.6l-12.36-25.67a3.458 3.458 0 00-4.6-1.61z"
        opacity={0.6}
      />
      <path
        d="M104.47 99.97a3.438 3.438 0 00.55 4.84l22.28 17.77c1.49 1.19 3.66.94 4.84-.55 1.19-1.49.94-3.66-.55-4.84l-22.28-17.77a3.438 3.438 0 00-4.84.55z"
        opacity={0.55}
      />
      <path
        d="M112.15 84.02c-.42 1.86.74 3.7 2.59 4.13l27.78 6.34c1.86.42 3.7-.74 4.13-2.59.42-1.86-.74-3.7-2.59-4.13l-27.78-6.34c-1.86-.43-3.71.73-4.13 2.59z"
        opacity={0.5}
      />
      <path
        d="M112.15 66.31a3.436 3.436 0 004.13 2.59l27.78-6.34a3.436 3.436 0 002.59-4.13 3.436 3.436 0 00-4.13-2.59l-27.78 6.34a3.46 3.46 0 00-2.59 4.13z"
        opacity={0.45}
      />
      <path
        d="M104.47 50.36a3.448 3.448 0 004.84.55l22.28-17.77a3.448 3.448 0 00.55-4.84 3.448 3.448 0 00-4.84-.55l-22.28 17.77c-1.49 1.19-1.74 3.36-.55 4.84z"
        opacity={0.4}
      />
      <path
        d="M90.63 39.33c1.71.83 3.77.11 4.6-1.61l12.36-25.67a3.44 3.44 0 00-1.61-4.6 3.44 3.44 0 00-4.6 1.61L89.02 34.73a3.44 3.44 0 001.61 4.6z"
        opacity={0.35}
      />
    </svg>
  );
}

export default SvgPageloader;
