import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './LanguageToggle.css'; // Import the CSS file
import { setLanguage } from '../../store/actions';

function LanguageToggle() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.Language);

  const handleLanguageChange = (lang) => {
    dispatch(setLanguage(lang));
  };

  return (
    <div className="language-toggle">
      <button
        className={`language-toggle-button ${language === 'en' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('en')}
        disabled={language === 'en'}
        title="English"
      >
        <div className="flag flag-en"></div>
      </button>
      <button
        className={`language-toggle-button ${language === 'pt' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('pt')}
        disabled={language === 'pt'}
        title="Portuguese"
      >
        <div className="flag flag-pt"></div>
      </button>
    </div>
  );
}

export default LanguageToggle;
