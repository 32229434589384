import { useSelector } from 'react-redux';
import enTranslations from '../locales/en/translation.json'; // Adjust path as necessary
import ptTranslations from '../locales/pt/translation.json'; // Adjust path as necessary

const useTranslations = () => {
  const language = useSelector((state) => state.app.Language);

  const translations = language === 'en' ? enTranslations : ptTranslations;

  return translations;
};

export default useTranslations;