import React, { useEffect } from 'react';
import './AcademicPage.css';
import '../../App.css';

import OrbitingStars from '../CursorLight/OrbitingStars/OrbitingStars';
import SpaceDust from '../SpaceDust/SpaceDust';
import DustParticles from '../DustParticles/DustParticles';
import useTranslations from '../../hooks/useTranslations';

function AcademicPage() {

  const translations = useTranslations();

  useEffect(() => {
    const handleScroll = () => {
      const items = document.querySelectorAll('.timeline-item');
      items.forEach(item => {
        const rect = item.getBoundingClientRect();

        if (rect.top + 100 < window.innerHeight && rect.bottom > 0) {
          item.classList.add('in-view');
        } else {
          //item.classList.remove('in-view');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="academicpage-container app-noselect">
      <OrbitingStars />
      <SpaceDust />
      <DustParticles />

      <div className="timeline-container">
        <div className="timeline-line"></div>

        <div className="timeline-section-title"><span>{translations.educationPage.titles[0]}</span></div>
        {translations.educationPage.education.map((item, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-date">{item.date}</div>
              <img src={require(`../../assets/images/academic/${item.image?.toLowerCase()}.png`)} alt="education logo" className="timeline-image" />
              <div className="timeline-details">
                <div className="education-degree"><span>{item.degree}</span></div>
                <div className="education-institution"><span>{item.institution}</span></div>
                <div className="education-details"><span>{item.details}</span></div>
              </div>
            </div>
          </div>
        ))}
        <div className="timeline-section-title"><span>{translations.educationPage.titles[1]}</span></div>
        {translations.educationPage.publications.map((item, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-date">{item.date}</div>
              <div className="timeline-details">
                <span className="highlighted-name">{item.authors.split(', ')[0]}</span>, {item.authors.split(', ').slice(1).join(', ')}
                <br />
                {item.title}
              </div>
            </div>
          </div>
        ))}

        <div className="timeline-section-title"><span>{translations.educationPage.titles[2]}</span></div>
        {translations.educationPage.oralCommunications.map((item, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-date">{item.date}</div>
              <div className="timeline-details">
                <span className="highlighted-name">{item.authors.split(', ')[0]}</span>, {item.authors.split(', ').slice(1).join(', ')}
                <br />
                {item.title}
              </div>
            </div>
          </div>
        ))}

        <div className="timeline-section-title"><span>{translations.educationPage.titles[3]}</span></div>
        {translations.educationPage.volunteering.map((item, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-content">
              <div className="timeline-date">{item.date}</div>
              <div className="timeline-details">
                <div className="education-degree"><span>{item.title}</span></div>
                <div className="education-institution"><span>{item.institution}</span></div>
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

export default AcademicPage;
