import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SpaceNavigation.css';
import useTranslations from '../../../hooks/useTranslations';

function SpaceNavigation() {
  const [loadingItem, setLoadingItem] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const translations = useTranslations();
  
  const handleMouseEnter = (event) => {
    if (disabled) return; // Prevent action if already loading

    const item = event.currentTarget;
    const percentage = item.querySelector('.space-navigation-loading-percentage');
    let progress = 0;

    percentage.style.opacity = 1; // Show the percentage element
    setDisabled(true); // Disable further actions

    const id = setInterval(() => {
      if (progress >= 100) {
        clearInterval(id);
        setLoadingItem(null);
        setDisabled(false);
        navigate(item.getAttribute('data-link'));
      } else {
        progress += 1;
        percentage.textContent = `${progress}%`;
      }
    }, 10);

    setLoadingItem(id);
  };

  const handleMouseLeave = () => {
    clearInterval(loadingItem); // Clear the interval
    setDisabled(false); // Re-enable actions
    setLoadingItem(null); // Clear the loading item

    document.querySelectorAll('.space-navigation-loading-percentage').forEach((el) => {
      el.style.opacity = 0; // Hide the percentage element
    });
  };

  return (
    <div className="space-navigation">
      <div
        className="scroll-text"
        id="scroll-text-1"
        data-link="/skills"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {translations.menu[1]}
        <div className="space-navigation-loading-percentage">0%</div>
      </div>
      <div
        className="scroll-text"
        id="scroll-text-2"
        data-link="/academic"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {translations.menu[2]}
        <div className="space-navigation-loading-percentage">0%</div>
      </div>
      <div
        className="scroll-text"
        id="scroll-text-3"
        data-link="/experience"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {translations.menu[3]}
        <div className="space-navigation-loading-percentage">0%</div>
      </div>

      <div
        className="scroll-text"
        id="scroll-text-4"
        data-link="/projects"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {translations.menu[4]}
        <div className="space-navigation-loading-percentage">0%</div>
      </div>

      <div
        className="scroll-text"
        id="scroll-text-5"
        data-link="/contacts"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {translations.menu[5]}
        <div className="space-navigation-loading-percentage">0%</div>
      </div>

    </div>
  );
}

export default SpaceNavigation;
