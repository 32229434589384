import React, { useEffect } from 'react';
import './LightStreaks.css';

function LightStreaks() {
    useEffect(() => {
        const streaksContainer = document.querySelector('.streaks-container');

        // Function to create and animate a streak
        const createAndAnimateStreak = (isHorizontal) => {
            const streak = document.createElement('div');
            streak.className = `streak ${isHorizontal ? 'horizontal-streak' : 'vertical-streak'}`;

            // Set random position aligning with the grid
            const gridSize = 100; // Grid size (100px)
            const maxOffset = isHorizontal ? window.innerHeight : window.innerWidth;
            const randomPosition = Math.floor(Math.random() * maxOffset / gridSize) * gridSize;

            if (isHorizontal) {
                streak.style.top = `${randomPosition}px`;
                streak.style.left = '-100%'; // Start position for horizontal streaks
            } else {
                streak.style.left = `${randomPosition}px`;
                streak.style.top = '-100%'; // Start position for vertical streaks
            }

            streaksContainer.appendChild(streak);

            // Remove streak after animation completes
            streak.addEventListener('animationend', () => {
                streak.remove();
            });
        };

        // Create and animate a streak at a regular interval
        const intervalId = setInterval(() => {
            createAndAnimateStreak(Math.random() < 0.5);
        }, 2000); // Adjust interval to create new streaks frequently

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return <div className="streaks-container"></div>;
}

export default LightStreaks;
