import React, { forwardRef } from 'react';
import './CheckeredSection.css'; // Importando o CSS necessário
import LightStreaks from './LightStreaks/LightStreaks';
import TitlePage from './TitlePage/TitlePage';
import useTranslations from '../../../hooks/useTranslations';

const CheckeredSection = forwardRef((props, ref) => {
  const translations = useTranslations();

  return (
    <div className="checkered-background" ref={ref}>
      <LightStreaks />
      <TitlePage />
      <div className="constellation">
        <div className="star" style={{ top: '10%', left: '70%' }}></div>
        <div className="star" style={{ top: '13.5%', left: '74%' }}></div>
        <div className="star" style={{ top: '17%', left: '57%' }}></div>
        <div className="star" style={{ top: '28%', left: '58%' }}></div>
        <div className="star" style={{ top: '40%', left: '68%' }}></div>
        <div className="star" style={{ top: '48%', left: '69%' }}></div>
        <div className="star" style={{ top: '47%', left: '30%' }}></div>
        <div className="star" style={{ top: '29%', left: '30%' }}></div>
        <div className="star" style={{ top: '54%', left: '18%' }}></div>
        <div className="star" style={{ top: '24%', left: '23%' }}></div>
      </div>

      {/* Adicionando a mensagem de "Em desenvolvimento" */}
      <div className="development-message">
        <p>{translations.inDevelopment}</p>
      </div>

      <div className="scroll-down-prompt">
        <div className="scroll-down-arrow"></div>
        <div className="scroll-down-text">{translations.scrollDown}</div>
      </div>
    </div>
  );
});

export default CheckeredSection;
