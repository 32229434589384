import React from 'react';
import './TitlePage.css';

function TitlePage() {
  return (
    <div className="title-page-wrapper">
      <div className="title-page-container">
        <span className="title-page-text">DANIEL PORTFOLIO</span>
        <span className="subtitle-text">Software Engineer</span>
      </div>
    </div>
  );
}

export default TitlePage;
