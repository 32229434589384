import React, { useEffect, useState } from 'react';
import './OrbitingStars.css';

function OrbitingStars() {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [angle, setAngle] = useState(0);
    const orbitRadius = 50; // Radius of the orbit

    useEffect(() => {
        const handleMouseMove = (event) => {
            setPosition({ x: event.clientX, y: event.clientY });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setAngle((prevAngle) => prevAngle + 2); // Adjust speed by changing the increment
        }, 16); // Approx. 60 FPS

        return () => clearInterval(interval);
    }, []);

    const starX = position.x + orbitRadius * Math.cos(angle * (Math.PI / 180));
    const starY = position.y + orbitRadius * Math.sin(angle * (Math.PI / 180));

    return (
        <>

            <div
                className="orbiting-star"
                style={{
                    left: `${starX + 10}px`,
                    top: `${starY + 10}px`,
                }}
            ></div>
        </>
    );
}

export default OrbitingStars;
