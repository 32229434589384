// src/store/configureStore.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk'; // Import thunk correctly
import rootReducer from './reducers'; // Your combined reducers

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['form'], // Optional: include only specific reducers
  // whitelist: ['language'], // Optionally persist only specific reducers
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with Redux Toolkit
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk), // Add thunk middleware
  //devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

// Create persistor
const persistor = persistStore(store);

export { store, persistor };


/*import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import { thunk } from 'redux-thunk';

import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  //whitelist: ["form"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
  let persistor = persistStore(store);
  return { store, persistor };
};

export default store;
~*/