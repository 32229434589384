import React, { Component } from "react";
//import ReactDOM from "react-dom";

import SvgPageLoader from "../../Icons/Pageloader";
import "./PageLoader.scss";

class PageLoader extends Component {
    render() {
        return(
          <div className="page-loader">
            <div className="page-loader__overlay"></div>
            <div className="page-loader__spinner">
              <SvgPageLoader />
              <div className="page-loader__spinner-text">{this.props.loaderText}</div>
            </div>
          </div>
        )
    }
}

export default PageLoader;
