import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';
import useTranslations from '../../hooks/useTranslations';

function Menu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(prevState => !prevState);
    };

    const translations = useTranslations();

    return (
        <>
            <button className="menu-toggle" onClick={toggleMenu}>
                {isOpen ? '✕' : '☰'} {/* Change the icon based on the menu state */}
            </button>
            <div className={`menu ${isOpen ? 'open' : ''}`}>

                <div className={`menu-items ${isOpen ? 'visible' : ''}`}>
                    <Link to="/" className="menu-item" onClick={toggleMenu}>{translations.menu[0]}</Link>
                    <Link to="/skills" className="menu-item" onClick={toggleMenu}>{translations.menu[1]}</Link>
                    <Link to="/academic" className="menu-item" onClick={toggleMenu}>{translations.menu[2]}</Link>
                    <Link to="/experience" className="menu-item" onClick={toggleMenu}>{translations.menu[3]}</Link>
                    <Link to="/projects" className="menu-item" onClick={toggleMenu}>{translations.menu[4]}</Link>
                    <Link to="/contacts" className="menu-item" onClick={toggleMenu}>{translations.menu[5]}</Link>
                </div>
            </div>
        </>

    );
}

export default Menu;
