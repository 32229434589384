import React, { useEffect } from 'react';
import './DustParticles.css';

function DustParticles() {
    useEffect(() => {
        const particlesContainer = document.querySelector('.dust-particles');

        const createParticle = () => {
            const particle = document.createElement('div');
            particle.className = 'particle';

            // Set random position within the container
            const size = Math.random() * 5 + 2; // Size between 2px and 7px
            const x = Math.random() * window.innerWidth;
            const y = Math.random() * window.innerHeight;
            particle.style.width = `${size}px`;
            particle.style.height = `${size}px`;
            particle.style.left = `${x}px`;
            particle.style.top = `${y}px`;

            // Append the particle to the container
            particlesContainer.appendChild(particle);

            // Animate the particle
            const animationDuration = Math.random() * 5 + 3; // Duration between 3s and 8s
            particle.style.animation = `fly ${animationDuration}s linear infinite`;

            // Remove particle after animation
            setTimeout(() => {
                particle.remove();
            }, animationDuration * 1000); // Remove after animation ends
        };

        // Create particles at a regular interval
        const intervalId = setInterval(createParticle, 100); // Adjust frequency as needed

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return <div className="dust-particles"></div>;
}

export default DustParticles;
