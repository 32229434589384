import React, { useEffect, useState } from 'react';
import './IntroductionSection.css';

import useTranslations from '../../../hooks/useTranslations';

function IntroductionSection({ scrollPosition }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const section = document.querySelector('.introduction-section');
    if (section) {
      const sectionTop = section.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;
      setIsVisible(sectionTop < viewportHeight * 0.65 && sectionTop >= -200);
    }
  }, [scrollPosition]);

  const translations = useTranslations();

  return (
    <section className={`introduction-section`}>
      <div className={`introduction-content ${isVisible ? 'visible' : ''}`}>
      <div className="introduction-photo"></div>
        <div className="introduction-text">
          <p>
            {translations.greeting}
          </p>
        </div>
      </div>
    </section>
  );
}

export default IntroductionSection;
