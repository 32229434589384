import { appConstants } from "../constants";

const initialState = {
  UserInfo: {},
  Language: "en", // Default language is set to English
  Timedout: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case appConstants.REFRESH:
      return {
        ...state,
        Timedout: true,
    };
    case appConstants.SET_LANGUAGE_REQUEST:
      return {
        ...state,  // Keep the existing state
        Language: action.payload, // Update only the Language property
      };
    default:
      return state; // Return the current state by default if no actions match
  }
};
