import React, { useEffect, useState } from "react";
import "./Layout.css";
import useTranslations from "../../hooks/useTranslations";
import PageLoader from "../../SharedComponents/PageLoader/PageLoader";
import Menu from "../Menu/Menu";
import LanguageToggle from "../LanguageToggle/LanguageToggle";

function Layout(props) {
  const translations = useTranslations();
  //put here header and footer

  const [menuOpen, setMenuOpen] = useState(false);


  if (translations) {
    return (
      <div className="layout">
        <LanguageToggle />

        <Menu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />


        <div className="page-wrapper">{props.children}</div>
      </div>
    );
  } else {
    return (
      <>
        <PageLoader />
      </>
    );
  }
}

export default Layout;

