import React, { useState, useEffect } from 'react';
import './ContactsPage.css';
import OrbitingStars from '../CursorLight/OrbitingStars/OrbitingStars';
import SpaceDust from '../SpaceDust/SpaceDust';
import DustParticles from '../DustParticles/DustParticles';
import useTranslations from '../../hooks/useTranslations';

function ContactsPage() {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);

  const translations = useTranslations(); // This changes when the language changes
  const fullText = translations.contactsPage.connectMessage; // New text based on the current language

  // Reset the animation when the language changes
  useEffect(() => {
    setIndex(0);
    setDisplayText('');
  }, [fullText]);

  useEffect(() => {
    // Check if index is less than the fullText length to continue the animation
    if (index < fullText.length) {
      const intervalId = setInterval(() => {
        setDisplayText((prevText) => prevText + fullText.charAt(index)); // Add character at the current index
        setIndex((prevIndex) => prevIndex + 1); // Increment the index
      }, 100); // Adjust speed as needed

      return () => clearInterval(intervalId); // Clear interval on unmount or change
    }
  }, [index, fullText]); // Re-run animation when index or fullText changes

  return (
    <div className='contactspage-container'>
      <OrbitingStars />
      <SpaceDust />
      <DustParticles />
      <div className="contact-box"> {/* Box for contact information */}
        <div className="animated-text">
          <span>{displayText}</span> {/* Animated text */}
        </div>
        <div className="social-container">
          <a
            href={translations.contactsPage.linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-link pulse-button" // Added pulse animation
          >
            <i className="fab fa-linkedin linkedin-icon"></i> {translations.contactsPage.linkedinText}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactsPage;
