import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';
import SkillsPage from './Components/SkillsPage/SkillsPage';
import ScrollTo from './SharedComponents/ScrollTo/ScrollTo';
import Layout from './Components/Layout/Layout';
import TimeoutHandler from './Components/TimeoutHandler/TimeoutHandler'; // Update path as necessary
import ExperiencePage from './Components/ExperiencePage/ExperiencePage';
import AcademicPage from './Components/AcademicPage/AcademicPage';
import ProjectsPage from './Components/ProjectsPage/ProjectsPage';
import ContactsPage from './Components/ContactsPage/ContactsPage';

function App() {

  /*
  useEffect(() => {
    // Verifica se há uma rota capturada na URL
    const searchParams = new URLSearchParams(window.location.search);
    const pathname = searchParams.get("?");

    if (pathname) {
      // Remove o ?pathname da URL e substitui pelo caminho correto
      window.history.replaceState(null, "", pathname);
    }
  }, []);
  */

  return (
    <Router basename="/">
      <ScrollTo X={0} Y={0}>
        <Routes>
          <Route path="/" element={<TimeoutHandler><Layout><HomePage /></Layout></TimeoutHandler>} />
          <Route path="/skills" element={<TimeoutHandler><Layout><SkillsPage /></Layout></TimeoutHandler>} />
          <Route path="/experience" element={<TimeoutHandler><Layout><ExperiencePage /></Layout></TimeoutHandler>} />
          <Route path="/academic" element={<TimeoutHandler><Layout><AcademicPage /></Layout></TimeoutHandler>} />
          <Route path="/projects" element={<TimeoutHandler><Layout><ProjectsPage /></Layout></TimeoutHandler>} />
          <Route path="/contacts" element={<TimeoutHandler><Layout><ContactsPage /></Layout></TimeoutHandler>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ScrollTo>
    </Router>
  );
}
export default App;
