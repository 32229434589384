import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { layoutActions } from '../../store/actions';

const TimeoutHandler = ({ children }) => {
  const dispatch = useDispatch();
  const Timedout = useSelector(state => state.app.Timedout);

  useEffect(() => {
    if (Timedout === true) {
      window.location.reload();
    }
  }, [Timedout]); // Dependency array includes Timedout

  return <>{children}</>;
};

export default TimeoutHandler;
