import React, { useEffect, useState, useRef } from 'react';
import './SkillsPage.css';
import OrbitingStars from '../CursorLight/OrbitingStars/OrbitingStars';
import SpaceDust from '../SpaceDust/SpaceDust';
import DustParticles from '../DustParticles/DustParticles';
import useTranslations from '../../hooks/useTranslations';

function SkillsPage() {
  const [playerPosition, setPlayerPosition] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [scrollRange, setScrollRange] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);
  const [showScrollMessage, setShowScrollMessage] = useState(true);
  const [fadeOutMessage, setFadeOutMessage] = useState(false);
  const [messageDismissed, setMessageDismissed] = useState(false);
  const carouselRef = useRef(null);

  const translations = useTranslations();

  const skillWidth = 800;
  const skillSpacing = 200;
  const skillContainerPadding = 150;

  const skills = translations?.skillsPage?.skills;

  useEffect(() => {
    const containerWidth = window.innerWidth * 0.95;
    setCarouselWidth(containerWidth);

    const totalSkillsWidth =
      skills.length * (skillWidth + skillSpacing) - skillSpacing +
      skillContainerPadding * 2;
    const maxScrollPosition = Math.max(0, totalSkillsWidth - containerWidth);
    setScrollRange(maxScrollPosition);
    setPlayerPosition(Math.min(playerPosition, containerWidth - 60));
  }, [translations, playerPosition]);

  const handleInteraction = () => {
    if (showScrollMessage && !messageDismissed) {
      setFadeOutMessage(true);
      setTimeout(() => {
        setShowScrollMessage(false);
        setMessageDismissed(true);
      }, 1000);
    }
  };

  const updatePlayerPosition = () => {
    const scrollLeft = carouselRef.current.scrollLeft;
    const newPlayerPosition = (scrollLeft / scrollRange) * (carouselWidth - 60);
    setPlayerPosition(Math.min(newPlayerPosition, carouselWidth - 60));
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.clientX);
    setScrollStart(carouselRef.current.scrollLeft);
    event.preventDefault();
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      handleInteraction();
      const dx = event.clientX - startX;
      const newScrollLeft = scrollStart - dx;
      requestAnimationFrame(() => {
        carouselRef.current.scrollLeft = newScrollLeft;
        updatePlayerPosition();
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleKeyDown = (event) => {
    if (!messageDismissed) handleInteraction();
    const step = 30;
    if (event.key === 'ArrowLeft') {
      const newPlayerPosition = Math.max(playerPosition - step, 0);
      const newScrollLeft = (newPlayerPosition / carouselWidth) * scrollRange;
      requestAnimationFrame(() => {
        carouselRef.current.scrollLeft = newScrollLeft;
        setPlayerPosition(newPlayerPosition);
      });
    } else if (event.key === 'ArrowRight') {
      const newPlayerPosition = Math.min(playerPosition + step, carouselWidth - 60);
      const newScrollLeft = (newPlayerPosition / carouselWidth) * scrollRange;
      requestAnimationFrame(() => {
        carouselRef.current.scrollLeft = newScrollLeft;
        setPlayerPosition(newPlayerPosition);
      });
    }
  };

  const handleTouchStart = (event) => {
    if (!messageDismissed) handleInteraction();
    setIsDragging(true);
    setStartX(event.touches[0].clientX);
    setScrollStart(carouselRef.current.scrollLeft);
  };

  const handleTouchMove = (event) => {
    if (isDragging) {
      handleInteraction();
      const dx = event.touches[0].clientX - startX;
      const newScrollLeft = scrollStart - dx;
      requestAnimationFrame(() => {
        carouselRef.current.scrollLeft = newScrollLeft;
        updatePlayerPosition();
      });
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const carousel = carouselRef.current;

    const handleScroll = () => {
      // Dismiss the scroll tip when scrolling
      if (showScrollMessage) {
        setFadeOutMessage(true);
        setTimeout(() => {
          setShowScrollMessage(false);
          setMessageDismissed(true);
        }, 1000);
      }
      updatePlayerPosition();
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('keydown', handleKeyDown);

    if (carousel) {
      carousel.addEventListener('touchstart', handleTouchStart);
      carousel.addEventListener('touchmove', handleTouchMove);
      carousel.addEventListener('touchend', handleTouchEnd);
      carousel.addEventListener('scroll', handleScroll); // Add scroll event listener
    }

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('keydown', handleKeyDown);

      if (carousel) {
        carousel.removeEventListener('touchstart', handleTouchStart);
        carousel.removeEventListener('touchmove', handleTouchMove);
        carousel.removeEventListener('touchend', handleTouchEnd);
        carousel.removeEventListener('scroll', handleScroll); // Clean up scroll event listener
      }
    };
  }, [isDragging, startX, scrollStart, carouselWidth, scrollRange, playerPosition]);

  return (
    <div className="skillspage-container">
      <OrbitingStars />
      <SpaceDust />
      <DustParticles />

      <div className="skills-title-container">
        <div className="skills-title">{translations.skillsPage.skillsTitle}</div>
      </div>

      <div className="skills-coding-container">

        {/* Scroll Message */}
        {showScrollMessage && (
          <div className={`scroll-message ${fadeOutMessage ? 'fade-out' : ''}`}>
            <p>{translations.skillsPage.tips}</p>
          </div>
        )}

        {/* Player character */}
        <div
          className="player"
          style={{ left: `${Math.max(0, Math.min(playerPosition, carouselWidth - 60))}px` }}
        ></div>

        {/* Carousel Container */}
        <div className="carousel-container" ref={carouselRef} style={{ width: carouselWidth }}>
          <div className="carousel-inner">
            {skills.map((skillSet, index) => (
              <div
                key={index}
                className="skill-card"
                style={{
                  left: `${index * (skillWidth + skillSpacing)}px`,
                }}
              >
                <div className="skill-title">{skillSet.title}</div>
                <div className="skills-list">
                  {skillSet.skills.map((skill, skillIndex) => (
                    <div key={skillIndex} className="skill">
                      <img
                        src={require(`../../assets/images/skills/${skillSet.images[skillIndex]?.toLowerCase()}.svg`)}
                        alt={skill}
                        className="skill-icon"
                      />
                      {skill}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="carousel-fog left"></div>
        <div className="carousel-fog right"></div>
      </div>

      <div className="skills-languages-container">
        <div className="skills-languages-title">{translations.skillsPage.languageTitle}</div>
        <div className="skills-language-bar portuguese-bar">
          <span>{translations.skillsPage.languages[0]}</span>
        </div>
        <div className="skills-language-bar english-bar">
          <span>{translations.skillsPage.languages[1]}</span>
        </div>
        <div className="skills-language-bar spanish-bar">
          <span>{translations.skillsPage.languages[2]}</span>
        </div>
        <div className="skills-language-bar french-bar">
          <span>{translations.skillsPage.languages[3]}</span>
        </div>
      </div>
    </div>
  );
}

export default SkillsPage;
